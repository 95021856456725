<script>
import Layout from "@/router/layouts/courses";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],

      'My Orders': 'Meus Pedidos',

      'Order': 'Pedido',
      'Date': 'Data',
      'Total': 'Total',
      'Status': 'Status',
      'Payment': 'Pagamento',
      'Details': 'Detalhes',
      'Pending': 'Pendente',
      'Approved': 'Aprovado',
      'Canceled': 'Cancelado',
      'View': 'Ver',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],

      'My Orders': 'Meus Pedidos',

      'Order': 'Pedido',
      'Date': 'Data',
      'Total': 'Total',
      'Status': 'Estado',
      'Payment': 'Pago',
      'Details': 'Detalles',
      'Pending': 'Pendiente',
      'Approved': 'Aprobado',
      'Canceled': 'Cancelado',
      'View': 'Ver',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: {
    Layout,
    VclList
  },
  data() {
    return {
      table: {
        heade: [
          'Order', 'Date', 'Curso', 'Total', 'Status', ''
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getOrders() {
      this.table.body = null

      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('courses/orders')
        .then(response => {
          if (response.data.status=='success') {
            this.table.body = response.data.list
          } else {
            this.table.body = null
          }
        })
        .catch(error => {
          this.table.errored = error
        })
        .finally(() => {
          this.table.loading = false
          if (this.table.body=='' || this.table.body==null) {
            this.table.empty = true
          }
        })
    },
  },
  mounted() {
    this.getOrders()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('My Orders') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.body" :key="index">
                    <td v-for="(data,row) in td" :key="row">
                      <div v-if="row === 'id'">
                        <strong>#{{ data }}</strong>
                      </div>
                      <div v-else-if="row === 'total'">
                        {{ data | currency }}
                      </div>
                      <div v-else class="notranslate">
                        {{ data }}
                      </div>
                    </td>
                    <td class="text-right">
                      <router-link :to="'/courses/orders/'+td.id" class="btn btn-default btn-sm btn-rounded">
                        {{ t('View') }}
                        <i class="mdi mdi-arrow-right ml-1"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>